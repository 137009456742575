<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>
          Edit {{ contact.full_name }}
          <router-link class="float-right" :to="`/customers/view/${contact.customer_id}`">
            <v-btn small>Customer details</v-btn>
          </router-link>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6 col-md-3">
        <v-text-field label="First name" v-model="contact.first_name" />
      </v-col>
      <v-col class="col-6 col-md-3">
        <v-text-field label="Last name" v-model="contact.last_name" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-md-6">
        <v-text-field label="Email" v-model="contact.email  " />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-md-6">
        <v-text-field label="Tel" v-model="contact.tel" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-md-6">
        <v-btn @click="saveChanges">Save changes</v-btn>    
      </v-col>
    </v-row>
    <v-dialog
      v-model="alert"
      max-width="400"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            x-small
            @click="alert = false"
          >
            Close
          </v-btn>
          &nbsp;
          <router-link :to="`/customers/view/${contact.customer_id}`">
            <v-btn
              x-small
              color="orange darken-2">
              Back to customer details
            </v-btn>
          </router-link>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'ContactsEdit',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      contact: null,
      alert: false,
      message: '',
    };
  },
  methods: {
    getContact() {
      const contactId = this.$route.params.id;
      axios.get(`/contacts/getSingle/${contactId}.json?token=${this.token}`)
        .then((response) => {
          this.contact = response.data.contact;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveChanges() {
      const postData = {};
      postData.contact = this.contact;
      axios.post(`/contacts/update.json?token=${this.token}`, postData)
        .then((response) => {
          this.contact = response.data.contact;
          this.message = 'The contact was updated';
          this.alert = true;
        })
        .catch((error) => {
          console.log(error);
          this.message = 'The contact could not be updated';
          this.alert = true;
        });
    },
  },
  mounted() {
    this.getContact();
  },
};
</script>
